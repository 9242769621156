<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">DELETE '{{ boost.name.toUpperCase() }}'</p>
    </header>
    <section class="modal-card-body">
      <p>Are you absolutely sure? This action <b>cannot</b> be undone.<br/>Please type <b>delete</b> to confirm.</p>
      <b-field label="Confirmation" class="mt-5">
        <b-input
          v-model="confirmation"
          required>
        </b-input>
      </b-field>
      <div class="columns mt-5">
        <div class="column is-6 is-offset-6 buttons">
            <b-button
              label="Cancel"
              type="is-primary"
              @click="$emit('close')" />
            <b-button
              label="Submit"
              type="is-danger"
              @click="submit"
              :disabled="'delete' !== confirmation" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { deleteBoost } from '@/api/boosts'
export default {
  props: {
    boost: {
      type: Object
    }
  },
  data: () => ({
    confirmation: ''
  }),
  methods: {
    async submit () {
      try {
        await deleteBoost(this.boost.id)
        this.$buefy.notification.open({
          message: `Boost <b>${this.boost.name}</b> deleted successfully!<br/>Refresh to view changes.`,
          type: 'is-success'
        })
        this.$emit('close')
        this.$emit('submit')
      } catch (err) {
        this.$buefy.notification.open({
          message: err.message,
          type: 'is-danger'
        })
      }
    }
  }
}
</script>

<style>

</style>
