<template>
    <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">EDIT '{{ boostData.name }}'</p>
    </header>
    <section class="modal-card-body">
      <b-field grouped>
        <b-field label="Name">
            <b-input
              v-model="form.name"
              placeholder="2x Tickets"
              required
              expanded />
        </b-field>
        <b-field label="Cost" style="width:15rem;">
            <b-numberinput
              v-model="form.cost"
              placeholder="5000"
              step="1"
              min="0"
              block controls-position="compact"
              controls-alignment="right" />
        </b-field>
        <b-field label="Hidden">
          <b-switch class="mt-2 ml-1" v-model="form.isHidden" type="is-info" />
        </b-field>
      </b-field>
      <b-field grouped>
        <b-field label="Type">
          <b-select required v-model="form.type">
            <option
              v-for="type in types"
              v-bind:key="type"
              :value="type">
              {{ type }}
            </option>
          </b-select>
        </b-field>
        <b-field>
          <b-field label="Multiplier" style="width:13rem;">
            <b-numberinput
              v-model="form.multiplier"
              placeholder="2"
              step="0.01"
              min="0"
              block controls-position="compact"
              controls-alignment="right" />
          </b-field>
          <b-field label="Duration (empty for always)">
            <b-input
              v-model="form.duration"
              placeholder="30 mins"
              required
              expanded />
          </b-field>
        </b-field>
      </b-field>
      <b-field grouped>
        <b-field label="Expires on">
            <b-datetimepicker
              placeholder="Leave blank if never"
              icon="calendar-today"
              :min-datetime="new Date()"
              v-model="form.expiresOn" />
        </b-field>
      </b-field>

      <div class="columns mt-5">
        <div class="column is-8 buttons">
            <b-button
              v-if="hasPermission('users.update')"
              label="Bulk Add"
              type="is-primary"
              @click="openBulkAddModal" />
        </div>
        <div class="column is-4 buttons">
            <b-button
              label="Cancel"
              type="is-primary"
              @click="$emit('close')" />
            <b-button
              label="Submit"
              type="is-info"
              @click="submit" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ms from 'ms'
import { updateBoost } from '@/api/boosts'
import { hasPermission } from '@/utils/permissions'
import BulkAddModal from './modals/BulkAdd.vue'

export default {
  props: {
    boostData: Object,
    types: Array
  },
  data: () => ({
    form: {
      name: null,
      description: null,
      cost: null,
      type: 'head',
      blacklists: [],
      isHidden: false,
      image: null
    }
  }),
  methods: {
    fillBlacklists () {
      this.form.blacklists = this.blacklists[this.form.type]
    },
    hasPermission (permission) {
      return hasPermission(permission)
    },
    openBulkAddModal () {
      this.$buefy.modal.open({
        parent: this,
        component: BulkAddModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          boostData: this.boostData
        }
      })
    },
    async submit () {
      try {
        await updateBoost(this.boostData.id, { ...this.form, duration: this.form.duration ? ms(this.form.duration) / 1000 : null })
        this.$buefy.notification.open({
          message: `Boost <b>${this.boostData.name}</b> updated successfully!<br/>Refresh to view changes.`,
          type: 'is-success'
        })
        this.$emit('close')
        this.$emit('submit', this.idx, this.form)
      } catch (err) {
        this.$buefy.notification.open({
          message: err.message,
          type: 'is-danger'
        })
      }
    }
  },
  mounted () {
    const { expiresOn, duration } = this.boostData
    this.form = {
      ...this.boostData,
      expiresOn: expiresOn ? new Date(expiresOn) : null,
      duration: duration ? ms(duration * 1000, { long: true }) : null
    }
  }
}
</script>
