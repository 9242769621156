<template>
    <div>
      <section class="hero is-primary">
        <div class="hero-body">
          <p class="title">
            Boosts
          </p>
          <p class="subtitle">
            Increase tickets and xp earning
          </p>
        </div>
        <div class="hero-foot">
          <nav class="tabs">
            <div class="container">
              <ul>
                <!-- TODO: search -->
                <li v-if="false">
                  <b-input
                    class="is-primary"
                    placeholder="Search..."
                    type="search"
                    v-model="searchQuery" />
                </li>
                <li v-if="hasPermission('boosts.create')"><b-button type="is-primary" @click="create">Create Boost</b-button></li>
              </ul>
            </div>
          </nav>
        </div>
      </section>
      <div class="container mt-3">
        <div class="columns is-vcentered">
          <div class="column is-one-quarter">
            <p><strong>Balance</strong>: {{ $store.state.user.user.settings.tickets }} tickets</p>
            <p v-if="!it.default"><small>(New Balance:  <i>{{ $store.state.user.user.settings.tickets - costDiscounted(boost.cost) }}</i> tickets)</small></p>

            <p>{{ computedOwned }}</p>

            <div class="buttons" v-if="!it.default">
              <b-button type="is-info" @click="purchase(false)">Purchase</b-button>
              <b-button type="is-info" @click="purchase(true)" v-if="owned(boost)">Activate</b-button>
              <b-button type="is-danger" v-if="hasPermission('boosts.update')" @click="edit">Edit</b-button>
              <b-button type="is-danger" v-if="hasPermission('boosts.delete')" @click="del">Delete</b-button>
            </div>
          </div>
          <div class="column" style="overflow-y:auto;max-height:70vh">
            <div class="type" v-for="type of boostTypes" :key="type">
              <h1>{{ capitalizeFirst(type) }}</h1>
              <div class="columns is-multiline is-mobile" v-if="types[type] && types[type].boosts.length" >
                <div
                  class="column is-4-mobile is-3-tablet"
                  v-for="(bst, i) in computedTypes[type].boosts"
                  :key="i"
                  style="cursor:pointer;"
                  @click="selectBoost(type, i)">
                  <div class="card">
                    <div class="card-image">
                      <b-image :src="images[bst.type]" :class="owned(bst) ? 'on-inventory' : costDiscounted(bst.cost) > $store.state.user.user.settings.tickets ? 'cant-purchase' : 'can-purchase'"/>
                    </div>
                    <div class="card-content">
                      <ul><strong>{{ bst.name }}</strong> <small v-if="owned(bst) !== 0">({{owned(bst)}} owned)</small></ul>
                      <ul>{{ bst.cost === 0 ? 'Free' : `${costDiscounted(bst.cost)} tickets` }} - {{ bst.multiplier }}x</ul>
                      <ul v-if="bst.duration"><strong>Lasts</strong> {{ ms(bst.duration) }}</ul>
                      <ul v-if="bst.expiresOn"><strong>Expires on</strong> {{ new Date(bst.expiresOn).toISOString().split('T').join(' at ').slice(0, -5) }}</ul>
                      <ul v-if="bst.isHidden && hasPermission('items.view.hidden')"><strong>Hidden Boost</strong></ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <infinite-loading @infinite="loadMore" spinner="spiral">
              <template slot="no-more">All boosts loaded.</template>
              <div slot="no-results"></div>
            </infinite-loading>
          </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import "@/sass/variables.scss";
.part
  margin-bottom: 10px;
  border-bottom: 1px solid $primary;

.cant-purchase
  border-bottom: 3px solid $danger !important;

.can-purchase
  border-bottom: 3px solid $warning !important;

.on-inventory
  border-bottom: 3px solid $secondary !important;

.clappy-item-active
  border-bottom: 3px solid $success !important;
</style>
<script>
import ms from 'ms'
import InfiniteLoading from 'vue-infinite-loading'
import { hasPermission } from '@/utils/permissions'
import { purchaseBoost, getUser } from '@/api/users'
import { getBoosts } from '@/api/boosts'
import CreateModal from './modals/Create'
import EditModal from './modals/Edit'
import DeleteModal from './modals/Delete'
import { getDiscounted } from '@/utils/utils'
import { getGuild } from '@/api/guild'

export default {
  components: {
    InfiniteLoading
  },
  data: () => ({
    images: {
      tickets: 'https://cdn.discordapp.com/attachments/875754054150660159/932435685430947840/tickets.png',
      xp: 'https://cdn.discordapp.com/attachments/875754054150660159/932435685699371078/xp.png'
    },
    types: {},
    activeType: 0,
    searchQuery: '',
    boostTypes: ['xp', 'tickets'],
    it: { default: true },
    guildSettings: {
      premiumStoreOff: 10
    }
  }),
  computed: {
    boost () {
      if (!this.types[this.it.type]) return null
      return this.types[this.it.type].boosts[this.it.i]
    },
    computedOwned () {
      if (!this.boost) return
      const owned = this.owned(this.boost)
      if (!owned) return null
      return this.isAvailable(owned)
    },
    computedTypes () {
      // TODO: search
      if (this.searchQuery) {
        const nTypes = {}
        for (const k of this.types) {
          const part = this.types[k].boosts
          const boosts = part.filter(i => i.name.toLowerCase().includes(this.searchQuery))
          if (boosts.length) nTypes[k].boosts = boosts
        }
        return nTypes
      }
      return this.types
    }
  },
  methods: {
    ms (time) {
      return ms(time * 1000, { long: true })
    },
    selectBoost (type, i) {
      this.it = { type, i }
    },
    capitalizeFirst (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    isAvailable (uboost) {
      const now = new Date()
      if (uboost.boost.expiresOn && uboost.boost.expiresOn < now) return -1

      if (uboost.activeSince) {
        // if the boost can only be activated after x
        if (uboost.activeSince > now) return -1

        if (uboost.boost.duration) {
          // If was used all time
          const tmpDate = new Date(uboost.activeSince)
          tmpDate.setSeconds(tmpDate.getSeconds() + uboost.boost.duration)

          if (tmpDate < now) return -1
          return 0
        }
      }

      return 1
    },
    owned (boost) {
      return this.$store.state.user.user.settings.boosts
        .find((b) => (b.boostId === boost.id && b.activeSince === null))
    },
    hasPermission (permission) {
      return hasPermission(permission)
    },
    processTypes () {
      for (const p of this.boostTypes) { this.$set(this.types, p, { page: 1, boosts: [], loaded: false }) }
    },
    async loadMore ($state) {
      const type = this.boostTypes[this.activeType]
      await this.fetchBoosts(type)
      if (this.types[type].page === 0) {
        this.activeType++
        if (this.activeType === this.boostTypes.length) {
          $state.complete()
          return
        }
      }

      $state.loaded()
    },
    async fetchBoosts (type) {
      const boosts = await getBoosts(this.types[type].page, type)
      this.types[type].boosts.push(...boosts)

      if (boosts.length < 50) this.types[type].page = 0 // all loading done
      else this.types[type].page++
    },
    purchase (owned) {
      const nTickets = this.$store.state.user.user.settings.tickets - this.costDiscounted(this.boost.cost)
      this.$buefy.dialog.confirm({
        message: owned ? 'Are you sure you want to activate this boost? ' : `Are you sure you want to purchase this boost for <strong>${this.costDiscounted(this.boost.cost)} tickets</strong>?<br/>Your new balance will be <strong>${nTickets} tickets</strong>`,
        onConfirm: async () => {
          try {
            await purchaseBoost('@me', { id: this.boost.id, activate: owned })

            const user = await getUser()
            await this.$store.dispatch('user/setUser', user)

            this.$buefy.notification.open({
              message: `Boost <b>${this.boost.name}</b> ${owned ? 'activated' : 'purchased'} successfully!`,
              type: 'is-success'
            })
          } catch (err) {
            this.$buefy.notification.open({
              message: err,
              type: 'is-danger'
            })
          }
        }
      })
    },
    create () {
      this.$buefy.modal.open({
        parent: this,
        component: CreateModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          types: this.boostTypes
        }
      })
    },
    edit () {
      this.$buefy.modal.open({
        parent: this,
        component: EditModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          boostData: this.boost,
          types: this.boostTypes
        }
      })
    },
    del () {
      this.$buefy.modal.open({
        parent: this,
        component: DeleteModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          boost: this.boost
        }
      })
    },
    costDiscounted (cost) {
      return getDiscounted(this.guildSettings.premiumStoreOff, cost, this.$store.state.user.user.settings)
    }
  },
  async beforeMount () {
    this.guildSettings = (await getGuild()).settings
  },
  mounted () {
    this.processTypes()
  }
}
</script>
