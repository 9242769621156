<template>
    <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">CREATE A NEW BOOST</p>
    </header>
    <section class="modal-card-body">
      <b-field grouped>
        <b-field label="Name">
            <b-input
              v-model="form.name"
              placeholder="2x Tickets"
              required
              expanded />
        </b-field>
        <b-field label="Cost" style="width:15rem;">
            <b-numberinput
              v-model="form.cost"
              placeholder="5000"
              step="1"
              min="0"
              block controls-position="compact"
              controls-alignment="right" />
        </b-field>
        <b-field label="Hidden">
          <b-switch class="mt-2 ml-1" v-model="form.isHidden" type="is-info" />
        </b-field>
      </b-field>
      <b-field grouped>
        <b-field label="Type">
          <b-select required v-model="form.type">
            <option
              v-for="type in types"
              v-bind:key="type"
              :value="type">
              {{ type }}
            </option>
          </b-select>
        </b-field>
        <b-field>
          <b-field label="Multiplier" style="width:13rem;">
            <b-numberinput
              v-model="form.multiplier"
              placeholder="2"
              step="0.01"
              min="0"
              block controls-position="compact"
              controls-alignment="right" />
          </b-field>
          <b-field label="Duration (empty for always)">
            <b-input
              v-model="form.duration"
              placeholder="30 mins"
              required
              expanded />
          </b-field>
        </b-field>
      </b-field>
      <b-field grouped>
        <b-field label="Expires on">
            <b-datetimepicker
              placeholder="Leave blank if never"
              icon="calendar-today"
              :min-datetime="new Date()"
              v-model="form.expiresOn" />
        </b-field>
      </b-field>

      <div class="columns mt-5">
        <div class="column is-4 is-offset-8 buttons">
            <b-button
                label="Cancel"
                type="is-primary"
                @click="$emit('close')" />
            <b-button
                label="Submit"
                type="is-info"
                @click="submit" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ms from 'ms'
import { createBoost } from '@/api/boosts'

export default {
  data: () => ({
    form: {
      name: null,
      duration: null,
      multiplier: null,
      cost: null,
      type: 'xp',
      isHidden: false
    }
  }),
  props: {
    types: Array
  },
  methods: {
    async submit () {
      try {
        await createBoost({ ...this.form, duration: this.form.duration ? ms(this.form.duration) / 1000 : null })
        this.$buefy.notification.open({
          message: `Boost <b>${this.form.name}</b> created successfully!<br/>Refresh to view changes.`,
          type: 'is-success'
        })
        this.$emit('close')
        this.$emit('submit', this.form)
      } catch (err) {
        this.$buefy.notification.open({
          message: err.message,
          type: 'is-danger'
        })
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "@/sass/darkmode.scss";

.multiselect .multiselect__tags,
.multiselect .multiselect__tags span,
.multiselect .multiselect__tags input {
  background-color: $ddarker;
  border-color: $ddarker;
  color: $text;
}

.multiselect .multiselect__content-wrapper {
  background-color: $ddark;
  border-color: $ddark;
  color: $text;
}

.multiselect .multiselect__option--selected,
.multiselect .multiselect__option--highlight,
.multiselect .multiselect__option--highlight::after {
  background-color: $dblue;
  border-block: $dblue;
  color: $text;
}

</style>
