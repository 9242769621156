<template>
    <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">BULK ADD '{{ boostData.name }}'</p>
    </header>
    <section class="modal-card-body">
        <b-field grouped>
            <b-field label="Roles">
                <multiselect
                    v-model="form.roles"
                    :options="roles"
                    multiple
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Pick roles"
                    :preselect-first="true"
                    track-by="id"
                    label="name">
                    <template #selection="{values, isOpen}">
                        <span
                            class="multiselect__single"
                            v-if="values.length && !isOpen">
                            {{ values.length }} roles selected
                        </span>
                    </template>
                </multiselect>
            </b-field>

            <b-field label="Users">
                <multiselect
                    v-model="form.users"
                    :options="users"
                    multiple
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Pick users"
                    :preselect-first="true"
                    track-by="id"
                    label="username">
                    <template #selection="{values, isOpen}">
                        <span
                            class="multiselect__single"
                            v-if="values.length && !isOpen">
                            {{ values.length }} users selected
                        </span>
                    </template>
                </multiselect>
            </b-field>
        </b-field>

        <div class="columns mt-5">
            <div class="column is-4 is-offset-8 buttons">
                <b-button
                    label="Cancel"
                    type="is-primary"
                    @click="$emit('close')" />
                <b-button
                    label="Submit"
                    type="is-info"
                    @click="submit" />
            </div>
      </div>
    </section>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { getUsers } from '@/api/users'
import { getGuild } from '@/api/guild'
import { boostBulkAdd } from '@/api/boosts'

export default {
  components: { Multiselect },

  props: {
    boostData: Object
  },
  data: () => ({
    users: [],
    roles: [],
    form: {
      roles: [],
      users: []
    }
  }),
  methods: {
    async submit () {
      const roles = this.form.roles.map(r => r.id)
      const users = this.form.users.map(r => r.id)

      try {
        this.$buefy.notification.open({
          message: 'Bulk add started. You\'ll receive a DM once finished.',
          type: 'is-info'
        })

        await boostBulkAdd(this.boostData.id, { roles, users })

        this.$emit('close')
      } catch (err) {
        this.$buefy.notification.open({
          message: err.message,
          type: 'is-danger'
        })
      }
    },
    async fetchUsers () {
      this.users = await getUsers()
    },
    async fetchRoles () {
      this.roles = Object.values((await getGuild()).roles)
    }
  },
  mounted () {
    const load = this.$buefy.loading.open()
    this.fetchUsers().then(() => this.fetchRoles().then(load.close))
  }
}
</script>

<style>

</style>
